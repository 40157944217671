

















































































































































import {Component} from 'vue-property-decorator';
import {
  namespace,
} from 'vuex-class';
import {validationMixin} from 'vuelidate';
import {required, sameAs} from 'vuelidate/lib/validators';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {RoutingHelper} from '@/helper/RoutingHelper';
import Company from '@/models/Company';
import {AuthResponse} from '@/interfaces/Responses';

const AuthStore = namespace('auth');
const UserStore = namespace('user');
const CompanyStore = namespace('company');

/**
 * This view provides a login for each user role.
 */
@Component({
  mixins: [validationMixin],
  validations: {
    data: {
      password: {required},
      passwordCopy: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
})
export default class SetPasswordView extends mixins(ErrorMessageHandlerMixin) {

  @AuthStore.Mutation('clearLogin')
  public clearLoginMutation!: () => void;
  @AuthStore.Mutation('saveLogin')
  public saveLogin!: (payload: AuthResponse) => void;
  @AuthStore.Action('validateHashAction')
  public validateHashAction!: (hash: string) => Promise<void>;
  @AuthStore.Action('setPasswordAction')
  public setPasswordAction!: (payload: { hash: string, password: string }) => Promise<AuthResponse>;
  @CompanyStore.Mutation('activeCompany')
  private activeCompany!: (company: Company) => void;

  /**
   * Data model
   */
  public data: { password?: string, passwordCopy?: string } = {
    password: undefined,
    passwordCopy: undefined,
  };

  /**
   * Contains the current error
   */
  public error: string | null = null;

  public formWasSubmitted: boolean = false;

  /**
   *  Small flag that hides the alert or password criteria box while initialization
   */
  public loading: boolean = true;

  /**
   * finish flag
   */
  public successfullySet: boolean = false;

  /**
   *  The hash that was passed through the route
   */
  private get hash(): string {
    return this.$route.params.hash;
  }

  private get type(): string {
    let type = this.$route.query.type as string;

    if (!type) {
      type = 'invite';
    }

    return type.toUpperCase();
  }

  get arePasswordCriteriaFulfilled(): boolean {
    return this.passwordCriteria.every((criterion) => criterion.fulfilled);
  }

  get hasError(): boolean {
    return !!this.error;
  }

  get isButtonDisabled(): boolean {
    return this.hasError || (this.$v.data!.$anyDirty || this.formWasSubmitted) && (!this.arePasswordCriteriaFulfilled || this.$v.data!.$invalid);
  }

  /**
   *  All password criteria that are necessary to add a new password
   */
  public passwordCriteria: Array<{ fulfilled: boolean, regEx: RegExp, display: string }> = [];

  public created() {
    // clear auth values to avoid consequence errors
    this.clearLoginMutation();
    this.setPasswordCriteria();
    this.validateHash();
  }

  /**
   * Sets the password criteria
   */
  private setPasswordCriteria() {
    this.passwordCriteria = [
      {
        fulfilled: false,
        regEx: /[a-z]+/,
        display: this.$t('SET_PASSWORD.CRITERIA.LOWER_CASE').toString(),
      },
      {
        fulfilled: false,
        regEx: /[A-Z]+/,
        display: this.$t('SET_PASSWORD.CRITERIA.UPPER_CASE').toString(),
      },
      {
        fulfilled: false,
        regEx: /\d+/,
        display: this.$t('SET_PASSWORD.CRITERIA.ONE_NUMBER').toString(),
      },
      {
        fulfilled: false,
        regEx: /.{8,}/,
        display: this.$t('SET_PASSWORD.CRITERIA.AT_LEAST').toString(),
      },
    ];
  }

  /**
   *  Validates the url hash
   */
  private async validateHash() {
    // check if token s available
    if (!this.hash) {
      this.error = this.$t('SET_PASSWORD.ERROR_NO_TOKEN').toString();
    }

    // check if token is valid
    if (this.hash) {
      try {
        await this.validateHashAction(this.hash);
      } catch (e) {
        // throws 404 error if not valid
        this.error = this.$t('SET_PASSWORD.ERROR_NO_TOKEN').toString();
      }
    }
    this.loading = false;
  }

  /**
   * Validate the password input regarding the password criteria a base criteria (e. g. required)
   * @param value
   */
  public validatePasswordInput(value: string) {
    // base criteria
    this.triggerValidation('data.password');
    // special password criteria
    this.passwordCriteria.forEach((criterion) => criterion.fulfilled = criterion.regEx.test(value));
  }

  /**
   * Make a request to set new password
   */
  public async setPasswordSubmit() {
    this.formWasSubmitted = true;
    this.$v.data!.$touch();

    if (!this.$v.data!.$invalid && this.arePasswordCriteriaFulfilled) {
      try {
        // sets the password and gets the user back
        const newUser = await this.setPasswordAction({hash: this.hash, password: this.data.password!});

        // saves the user
        this.saveLogin(newUser);

        let optionalParams;

        // consider company id to route
        if (!this.$userRoleHandler.isSuperAdmin()) {
          this.activeCompany(newUser.user.company as Company);
          optionalParams = {
            companyId: (newUser.user.company as Company).id!,
          };
        }

        // changes the route to the default login route
        await this.$router.push({
          name: RoutingHelper.getDefaultRoute(),
          params: optionalParams,
        });

        this.$notifySuccessSimplified('SET_PASSWORD.NOTIFICATIONS.SET_PASSWORD.SUCCESS');
        this.successfullySet = true;
      } catch (e) {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
    }
  }
}
